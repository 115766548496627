import {
    Component,
    EventEmitter, Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {SongModel} from '../../../models/SongModel';
import {shareReplay} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {Constants} from '../../../helpers/Constants';
import {Helpers} from '../../../helpers/Helpers';

@Component({
    selector: 'StandardSession',
    templateUrl: './standard.component.html',
})
export class StandardSessionComponent implements OnInit, OnDestroy {

    @Output() btnClicked = new EventEmitter<string>();
    @Output() standardSongItemClicked = new EventEmitter<SongModel>();
    @Output() upvoteSongItemClicked = new EventEmitter<SongModel>();
    @Output() downvoteSongItemClicked = new EventEmitter<SongModel>();

    @Input() isFullyControl_party: string;
    @Input() songs: SongModel[] = [];
    noLocation = false;
    collectionSubscribe: any;
    isStartedPlaylist = false;
    addressName = '';
    defaultImage = '../../assets/images/image01.png';
    showArtistName = true;

    constructor(private firestore: AngularFirestore) {

    }

    ngOnDestroy(): void {
        this.songs.forEach(s => {
            // s.FirebaseSubscribe.unsubscribe();
        });
    }

    ngOnInit(): void {
        this.subscribeFirestore();
        setInterval(() => {
            this.showArtistName = !this.showArtistName;
            this.songs.forEach(s => {
                this.showArtistName ? s.Description = s.Artist : s.Description = 'Picked by ' + s.Requester;
            });
        }, 3000);
    }

    setIsStartedPlaylist() {
        this.isStartedPlaylist = this.songs.length > Constants.NUMBER_OF_SONG_REQUEST;
    }

    subscribeFirestore() {
        let placeId = localStorage.getItem(Constants.SETTINGS_PLACEID);
        let address = localStorage.getItem(Constants.SETTINGS_ADDRESS);
        this.collectionSubscribe = this.firestore.collection('tb_standard_request', ref => ref
            .where('placeId', '==', placeId)
            .where('businessName', '==', address)
            .where('enable', '==', '0'))
            .snapshotChanges()
            .pipe(shareReplay());

        this.collectionSubscribe.subscribe(data => {
            let changedData = data as any;
            let uuid = Helpers.generateUUID();
            changedData.forEach((item) => {
                let data = item.payload.doc.data();
                let foundSong = this.songs.find(x => x.key == item.payload.doc.id);
                if (!foundSong) {
                    let upvoted = false;
                    if (data.uuids.includes(uuid)) {
                        upvoted = true;
                    }
                    const s = {
                        key: data.key, Uri: data.songKey,
                        TrackId: data.songKey, Artist: data.songArtist,
                        Thumb: data.songThumb, Title: data.songName,
                        Enable: true, NoRequested: data.noRequested,
                        Requester: data.firstName + ' ' + data.lastName,
                        AppleMusic: data.link_apple_music,
                        Duration: Number(data.duration_ms),
                        UUID: data.uuid, Upvoted: upvoted, Time: data.time,
                        ItemType: 1, Description: data.songArtist
                    } as SongModel;
                    this.songs.push(s);
                    this.subscribeSong(s);
                }
            });
        });
    }

    rearrange(): void {
        let tempSongs = this.songs.filter(item => item.ItemType == 1);
        this.songs = [];
        tempSongs.sort((a, b) => a.Time - b.Time);
        tempSongs.sort((a, b) => b.NoRequested - a.NoRequested);

        let countDuration = 0;
        let hasSeparator = false;
        tempSongs.forEach(s => {
            if (this.isFullyControl_party != '1') {
                countDuration += s.Duration;
                if (!hasSeparator && countDuration > 1500000) {
                    const separator = {
                        ItemType: 2,
                        Duration: 0
                    } as SongModel;
                    this.songs.push(separator);
                    hasSeparator = true;
                }
            }
            this.songs.push(s);
        });

        if (!this.isStartedPlaylist && this.songs.length > 0) {
            const item = {
                ItemType: 3,
                Duration: 0
            } as SongModel;
            this.songs.push(item);
        }
    }

    removeItemType3FromSongs() {
        let tempSongs = this.songs.filter(item => item.ItemType != 3);
        this.songs = [];
        tempSongs.forEach(s => {
            this.songs.push(s);
        });
    }

    updateSongs(): void {
        this.rearrange();
    }

    subscribeSong(s: SongModel) {
        let firebaseSubscribe = this.firestore.collection('tb_standard_request')
            .doc(s.key)
            .snapshotChanges()
            .pipe(shareReplay());
        s.FirebaseSubscribe = firebaseSubscribe;

        firebaseSubscribe.subscribe(data => {
            let changedData = data as any;
            let songData = changedData.payload.data();
            if (songData.enable == '2') {
                let foundSong = this.songs.find(x => x.key == changedData.payload.id);
                if (foundSong) {
                    let index = this.songs.indexOf(foundSong);
                    this.songs.splice(index, 1);
                }
            } else {
                let foundSong = this.songs.find(x => x.key == changedData.payload.id);
                if (foundSong) {
                    let noRequested = changedData.payload.data().noRequested;
                    foundSong.NoRequested = noRequested;
                }
            }
            this.rearrange();
        });
    }

    onSongSelect(item: SongModel): void {
        this.standardSongItemClicked.emit(item);
    }

    onUpvoteClicked(item: SongModel): void {
        this.upvoteSongItemClicked.emit(item);
    }

    onDownvoteClicked(item: SongModel): void {
        this.downvoteSongItemClicked.emit(item);
    }

    btnSearchSongClicked() {
        this.btnClicked.emit('btnSearchSongClicked');
    }

    btnShareClicked() {
        this.btnClicked.emit('btnShareClicked');
    }
}
