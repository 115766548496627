import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/AuthService";
import { AppService } from "../../services/app.service";
import { BaseComponent } from "../base/base.component";
import { Location } from "@angular/common";
import { WebService } from "../../webServices/WebService";

declare var window: any;
declare var $: any;

@Component({
  templateUrl: "partyDashboard.component.html",
})
export class PartyDashboardComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {

  songs = [];
  hasTopPart = false;
  hasGenre = false;
  hasBPM = false;
  hasMood = false;
  genrePercent = 0;
  genreTitle = "";

  bpmPercent = 0;
  bpmTitle = "";

  moodPercent = 0;
  moodTitle = "";

  songAd = [];
  defaultImage = "../../assets/images/image01.png";

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public authAppService: AuthService,
    public appService: AppService,
    public http: HttpClient,
    public webService: WebService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public location: Location,
  ) {
    super(
      router,
      route,
      authAppService,
      appService,
      http,
      webService,
      toastr,
      spinner,
      location
    );
  }

  ngOnInit(): void {
    this.loadEvent();
    this.initAd();
  }

  refreshPage(): void {

  }

  ngAfterViewInit(): void { }

  initAd() {
    this.loadExternalScript(
      "https://securepubads.g.doubleclick.net/tag/js/gpt.js"
    ).then(() => {
      window.googletag = window.googletag || { cmd: [] };
    });
  }
}
