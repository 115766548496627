import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private venue = '';
  public get Venue(): string {
    return this.venue;
  }
  public set Venue(venue: string) {
    this.venue = venue;
  }

  public btnWrongVenueClicked = new Subject()
  public btnMenuClicked = new Subject()
  public btnSpotifyClicked = new Subject()
  public updateSpotifyPlaylist = new Subject()
  public getSpotifyPlaylist = new Subject()

  constructor() { }
}
