import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: "DesktopModal",
    templateUrl: "./desktopModal.component.html",
})
export class DesktopModalComponent implements OnInit {
    @ViewChild("desktopModal") public thisModal: ModalDirective;
    @Output() btnClicked = new EventEmitter<string>();

    constructor() { }

    ngOnInit(): void {

    }

    btnDesktopContinueClicked() {
        this.btnClicked.emit("");
    }
}
