import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Constants } from './helpers/Constants';
import { AppService } from './services/app.service';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    public appService: AppService) {
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#6b6b6b",
          text: "#FFF"
        },
        button: {
          background: "#FFF",
          text: "#000"
        }
      },
      theme: "classic",
      content: {
        message: "This website uses cookies to ensure you get the best experience on our website",
        dismiss: "Got it!",
        link: "Learn more",
        href: "/html/privacy.html"
      }
    });
  }

  btnLogoClicked() {
    location.reload();
  }

  btnWrongVenueClicked() {
    this.appService.btnWrongVenueClicked.next(Constants.EVENT_BUS_WRONG_LOCATION);
  }

  btnMenuClicked() {
    this.appService.btnMenuClicked.next(Constants.EVENT_BUS_MENU);
  }

  btnSpotifyClicked() {
    this.appService.btnSpotifyClicked.next(Constants.EVENT_BUS_SPOTIFY);
  }
}
